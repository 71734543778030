<template>
  <v-container
    class="alerts-table-container drawer-detection-container"
    :class="drawer ? 'drawer-open' : 'drawer-closed'"
  >
    <v-card>
      <v-data-table
        class="alerts-table full-width-table"
        :headers="headers"
        :items="displayAlerts"
        :loading="currentPageLoading"
        :server-items-length="totalCount"
        @update:options="paginate($event)"
        :footer-props="footerProps"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Alerts</v-toolbar-title>
          </v-toolbar>
        </template>

        <template v-slot:[`item.alertType`]="{ item }">
          <span
            class="alert-type"
            :class="item.alertType.color"
          >
            {{item.alertType.text}}
          </span>
        </template>

        <template v-slot:[`item.message`]="{ item }">
          {{item.message}}
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ moment(item.createdAt).format(dateTimeMomentFormat) }}
        </template>

        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ moment(item.updatedAt).format(dateTimeMomentFormat) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <router-link
            :to="`/alerts/${item.id}`"
          >
            <v-btn
              x-small
              class="go-to-alert-btn"
            >
              Go to Alert
            </v-btn>
          </router-link>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Vue from "vue";
import {
  dateTimeMomentFormat, dismissAction, logAndExtractMessage,
  paginateTable,
} from "../../utils/utils";

export default {
  name: "Alerts",

  data: () => ({
    moment,
    dateTimeMomentFormat,

    localStorageTableSizeKey: "alerts.table.size",

    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    headers: [
      { text: "Type", value: "alertType", sortable: false },
      { text: "Message", value: "message", sortable: false },
      { text: "Created at", value: "createdAt", sortable: false },
      { text: "Updated at", value: "updatedAt", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState("coreModule", [
      "drawer",
    ]),
    ...mapState("alertsModule", [
      "displayAlerts",
      "totalCount",
      "currentPageLoading",
    ]),
  },

  methods: {
    paginate(data) {
      data.itemsPerPage = paginateTable(
        data,
        this.localStorageTableSizeKey,
        this.tableInited,
      );
      const currentPage = {
        index: data.page - 1,
        size: data.itemsPerPage,
      };

      this.loadPage(currentPage);
      this.tableInited = true;
    },

    async loadPage(currentPage) {
      try {
        await this.$store.dispatch("alertsModule/loadPage", currentPage);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },
  },
};
</script>

<style scoped>
  .alerts-table-container >>> table {
    table-layout: fixed;
  }
</style>
